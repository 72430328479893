export const NescoBranches = [
  {
    id: '1',
    nameAr: 'أبها',
    nameEn: 'Abha',
    center: [18.246342854649495, 42.51114517267884],
    branches: [
      {
        id: '1',
        nameAr: 'طريق الملك عبدالله، الربوة',
        nameEn: 'King Abdullah Rd, Al Rabwah',
        address: 'King Abdullah Rd, Al Rabwah, 62523',
        hotNum: '62521',
        phone: '8007550022',
        latLang: [18.19207072576168, 42.527654928835375],
        location: 'https://maps.app.goo.gl/HUAWXNFQXUz6hKgc7',
      },
      {
        id: '2',
        nameAr: 'طریق الملك عبد العزیز - al sarawat',
        nameEn: 'King Abdul Aziz Rd, Alsarawat',
        address: 'King Abdul Aziz Rd, Alsarawat, Abha Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [18.20025692959818, 42.496978522727495],
        location: 'https://maps.app.goo.gl/esiJiyEzyptk5t7WA',
      },
      {
        id: '3',
        nameAr: 'طريق الملك عبد العزيز، الوردتين',
        nameEn: 'King Abdul Aziz Rd, Al Wardatain',
        address: 'King Abdul Aziz Rd, Al Wardatain, Abha 62521, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [18.231655048168573, 42.497779746023724],
        location: 'https://maps.app.goo.gl/h5WoUzswix6nseLE8',
      },
      {
        id: '4',
        nameAr: 'طريق الأمير سلطان،ضمك',
        nameEn: 'Prince Sultan Road, Damak',
        address: 'Prince Sultan Road, Damak 62438, Abha 62438, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [18.238371703205026, 42.68196290487064],
        location: 'https://maps.app.goo.gl/MbJjDvFQ7NdsGXfj6',
      },
      {
        id: '5',
        nameAr: '2649 طريق الملك فهد',
        nameEn: '2649 King Fahd Road',
        address: '2649 King Fahd Road, Abha 62437 7982, Saudi Arabia',
        hotNum: '62581',
        phone: '8007550022',
        latLang: [18.25645029678984, 42.63282991044139],
        location: 'https://maps.app.goo.gl/V7Rwjj8YiG53LuZy7',
      },
      {
        id: '6',
        nameAr: '2230, المحالة',
        nameEn: '2230, Al Mahalah',
        address: '2230, Al Mahalah, Abha 62562, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [18.243448658028093, 42.60162595469584],
        location: 'https://maps.app.goo.gl/rTqatgRe2SiLE3yx5',
      },
      {
        id: '7',
        nameAr: '6912 طريق الملك فهد، حي العرين',
        nameEn: '6912 King Fahd Rd, Al Areen District',
        address:
          '6912 King Fahd Rd, Al Areen District, ABDB4710, 4710, Abha 62524, Saudi Arabia',
        phone: '',
        latLang: [18.226603240852377, 42.54195870674704],
        location: 'https://maps.app.goo.gl/Zs9kPVFKb9W1otk56',
      },
      {
        id: '8',
        nameAr: 'البديع',
        nameEn: 'Al Badi, Abha',
        address: 'Al Badi, Abha 62581, Saudi Arabia',
        phone: '8007550022',
        latLang: [18.23510453486883, 42.56350325699],
        location: 'https://maps.app.goo.gl/YZohvSKV2gX6fRJP9',
      },
      {
        id: '9',
        nameAr: '15',
        nameEn: '15, Abha',
        address: '15, Abha 62598, Saudi Arabia',
        phone: '554324763',
        latLang: [18.398609116112365, 42.4695824190356],
        location: 'https://maps.app.goo.gl/UCN9Qo1QJQHqMQtf7',
      },
      {
        id: '10',
        nameAr: 'طريق الملك فهد، حجلة',
        nameEn: 'King Fahd Rd, Hajlah',
        address: 'King Fahd Rd, Hajlah، Abha 62563, Saudi Arabia',
        phone: '554324763',
        latLang: [18.266132646182808, 42.64729903436711],
        location: 'https://maps.app.goo.gl/okLSscZgYfLod7Ej6',
      },
    ],
  },
  {
    id: '2',
    nameAr: 'خميس مشيط',
    nameEn: 'Khamis Mushait',
    center: [18.30857195264389, 42.764863479066996],
    branches: [
      {
        id: '1',
        nameAr: 'سعد بن أبي وقاص، الرصراص',
        nameEn: 'Sad Ibn Abi Waqas, Al Rasras',
        address:
          'Sad Ibn Abi Waqas, Al Rasras, Khamis Mushait 62441, Saudi Arabia',
        hotNum: '',
        phone: '8007550022',
        latLang: [18.256249696817928, 42.784934056445664],
        location: 'https://maps.app.goo.gl/yrfgZ3gXGDy9NwT28',
      },
      {
        id: '2',
        nameAr: 'طريق الملك عبدالله، قرى آل غليظ',
        nameEn: 'King Abdullah Road, Al Ghaleez Villages',
        address:
          'King Abdullah Road, Al Ghaleez Villages, Khamis Mushait 62589, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [18.187967452165978, 42.65621279173594],
        location: 'https://maps.app.goo.gl/RM6ckaiFNhcSSKja7',
      },
      {
        id: '3',
        nameAr: 'حجله، خمیس مشیط',
        nameEn: 'Hijlah, Khamis Mushait',
        address: 'Hijlah, Khamis Mushait 62451, Saudi Arabia',
        hotNum: '',
        phone: '8007550022',
        latLang: [18.27550369357626, 42.66586756935644],
        location: 'https://maps.app.goo.gl/LEJYnNtfHjcsJLjeA',
      },
      {
        id: '4',
        nameAr: 'طريق المحالة، القافلة',
        nameEn: 'Al Mahalah Rd, Al Qafilah',
        address:
          'Al Mahalah Rd, Al Qafilah, Khamis Mushait 62454, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [18.324047173668035, 42.69415785710682],
        location: 'https://maps.app.goo.gl/7GnADq2RJPxdHgZw5',
      },
      {
        id: '5',
        nameAr: 'طريق تندحة، الموسى',
        nameEn: 'Tandaha Road, Al Mousa',
        address: 'Tandaha Road, Al Mousa, Khamis Mushayt 62454, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [18.326204224940945, 42.757603542932124],
        location: 'https://maps.app.goo.gl/EqZKhWJYVdCPe8iYA',
      },
      {
        id: '6',
        nameAr: 'طریق الأمیر سلطان، الشفاء، خمیس مشیط',
        nameEn: 'Prince Sultan Rd, Al Shifa, Khamis Mushait',
        address:
          'Prince Sultan Rd, Al Shifa, Khamis Mushait 62433, Saudi Arabia',
        hotNum: '62433',
        phone: '554324763',
        latLang: [18.277208091015527, 42.72353230979971],
        location: 'https://maps.app.goo.gl/woeabTpPZBH57aJv8',
      },
      {
        id: '7',
        nameAr: 'الربيع',
        nameEn: 'Ar Rabie',
        address: 'Ar Rabie, Khamis Mushayt 62454, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [18.30496135820765, 42.69379078193624],
        location: 'https://maps.app.goo.gl/9qerRLRBivegWw9V9',
      },
      {
        id: '8',
        nameAr: 'طريق صلاح الدين',
        nameEn: 'Salahuddin Road',
        address: 'Salahuddin Road, Khamis Mushait 62432, Saudi Arabia',
        hotNum: '',
        phone: '542898903',
        latLang: [18.29377718496113, 42.70800273037096],
        location: 'https://maps.app.goo.gl/EDmLwvXrDFfszu3z5',
      },
      {
        id: '9',
        nameAr: 'طريق الأمير سلطان، الشفاء',
        nameEn: 'Prince Sultan Rd, Al Shifa',
        address:
          'Prince Sultan Rd, Al Shifa, Khamis Mushait 62433, Saudi Arabia',
        hotNum: '',
        phone: '542898903',
        latLang: [18.268288032357166, 42.7406584880432],
        location: 'https://maps.app.goo.gl/hz6peeRmnkhUBUWp8',
      },
      {
        id: '10',
        nameAr: 'الرصاص',
        nameEn: 'Al Rasras',
        address: 'Al Rasras, Khamis Mushait 62444, Saudi Arabia',
        phone: '554324763',
        latLang: [18.24612857888205, 42.78534895769009],
        location: 'https://maps.app.goo.gl/FYHPVEvmiHJNJbvV6',
      },
    ],
  },
  {
    id: '3',
    nameAr: 'صبيا',
    nameEn: 'Sabya',
    center: [17.15538572391517, 42.62669358074856],
    branches: [
      {
        id: '1',
        nameAr: 'طريق الملك عبد العزيز، المروج',
        nameEn: 'King Abdul Aziz Rd, Al Muruj',
        address: 'King Abdul Aziz Rd, Al Muruj, Sabya 85298, Saudi Arabia',
        hotNum: '',
        phone: '8007550022',
        latLang: [17.145952322460055, 42.645525730118685],
        location: 'https://maps.app.goo.gl/pdf2a3YEuAkAHQSv7',
      },
    ],
  },
  {
    id: '4',
    nameAr: 'الدرب',
    nameEn: 'Al DARB',
    center: [17.734138499024542, 42.26588763441278],
    branches: [
      {
        id: '1',
        nameAr: 'طريق الملك عبد العزيز، أبو السداد',
        nameEn: 'King Abdul Aziz Rd, Abu AlSadad',
        address: 'King Abdul Aziz Rd, Abu AlSadad, Ad Darb 89874, Saudi Arabia',
        hotNum: '',
        phone: '8007550022',
        latLang: [17.742225619191924, 42.282093964112754],
        location: 'https://maps.app.goo.gl/P7sAg7Y7TD6vGMjd9',
      },
      {
        id: '2',
        nameAr: 'طريق الملك عبد العزيز، أبو السداد',
        nameEn: 'King Abdul Aziz Rd, Abu AlSadad',
        address: 'King Abdul Aziz Rd, Abu AlSadad, Ad Darb 89874, Saudi Arabia',
        hotNum: '',
        phone: '8007550022',
        latLang: [17.716883700584972, 42.23353739706232],
        location: 'https://maps.app.goo.gl/KWDV3R7htrAv2arA6',
      },
    ],
  },
  {
    id: '5',
    nameAr: 'بيش',
    nameEn: 'Baish',
    center: [17.374606177332268, 42.53592167215909],
    branches: [
      {
        id: '1',
        nameAr: 'البطنة',
        nameEn: 'AL-BATNA',
        address: 'King Abdullah Rd, AL-BATNA 87521, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [17.38331724893886, 42.462322199984584],
        location: 'https://maps.app.goo.gl/3xukJaww1wi2oBm3A',
      },
      {
        id: '2',
        nameAr: 'المطعن',
        nameEn: 'Al-Matan',
        address: 'King Abdul Aziz Rd, Al-Matan, Baish 87515, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [17.428450972441123, 42.531001609196956],
        location: 'https://maps.app.goo.gl/1RB9wuTDmsYMpsGQ6',
      },
    ],
  },
  {
    id: '6',
    nameAr: 'جازان',
    nameEn: 'Jazan',
    center: [16.889981138235044, 42.57040164693723],
    branches: [
      {
        id: '1',
        nameAr: 'طريق الملك عبدالله، المنطقة الصناعية',
        nameEn: 'King Abdullah Rd, Industrial Area',
        address: 'King Abdullah Rd, Industrial Area, Jazan 82621, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [16.834451010472716, 42.608160465020404],
        location: 'https://maps.app.goo.gl/dxYFVTsg7VfcfDa99',
      },
      {
        id: '2',
        nameAr: 'طريق الملك عبد العزيز',
        nameEn: 'King Abdul Aziz Rd',
        address: 'King Abdul Aziz Rd, Jazan 82847, Saudi Arabia',
        hotNum: '',
        phone: '554324763',
        latLang: [16.957522603229876, 42.61125615156528],
        location: 'https://maps.app.goo.gl/bEktEoZ5j1DFqn7f6',
      },
      {
        id: '3',
        nameAr: 'طريق الملك عبدالله، الصفا',
        nameEn: 'King Abdullah Road, Al Safa',
        address: 'King Abdullah Road, Al Safa, Jazan 82721, Saudi Arabia',
        phone: '8007550022',
        latLang: [16.888305284996537, 42.55898162457714],
        location: 'https://maps.app.goo.gl/8n82xHvHhstryVF3A',
      },
      {
        id: '4',
        nameAr: 'طريق الكورنيش، الشاطئ',
        nameEn: 'Corniche Rd, Ash Shati',
        address: 'Corniche Rd, Ash Shati, Jazan 82725, Saudi Arabia',
        phone: '8007550022',
        latLang: [16.903593950229947, 42.54564286377597],
        location: 'https://maps.app.goo.gl/A9iNBUEb4fTePiey9',
      },
    ],
  },

  {
    id: '7',
    nameAr: 'بيشة',
    nameEn: 'Bishah',
    center: [19.976364116948883, 42.589919881379984],
    branches: [
      {
        id: '2',
        nameAr: 'التضامن، بیشة',
        nameEn: 'Altdhamun, Bisha',
        address: 'Altdhamun, Bisha 67911, Saudi Arabia',
        hotNum: '67911',
        phone: '554324763',
        latLang: [20.050807919908202, 42.58259552118853],
        location: 'https://maps.app.goo.gl/L3bX2kUNc2b4XCVz7',
      },
      {
        id: '3',
        nameAr: 'طریق الملك خالد، الخزامى، بیشة',
        nameEn: 'King Khalid Rd, Alkhuzama, Bisha',
        address: 'King Khalid Rd, Alkhuzama, Bisha 67711, Saudi Arabia',
        hotNum: '67711',
        phone: '554324763',
        latLang: [20.012910207368265, 42.60692503222908],
        location: 'https://maps.app.goo.gl/oPdfs5jHL8AWaFHRA',
      },
      {
        id: '4',
        nameAr: 'الامیر سلطان، بیشة',
        nameEn: 'Alamir Sultan, Bisha',
        address: 'Alamir Sultan, Bisha 67721, Saudi Arabia',
        hotNum: '67721',
        phone: '8007550022',
        latLang: [19.95501099530802, 42.6049402757066],
        location: 'https://maps.app.goo.gl/JoipVzLcRzQF5zCe6',
      },
    ],
  },
  {
    id: '8',
    nameAr: 'أحد رفيدة',
    nameEn: 'Ahad Rafidah',
    center: [18.19572223996049, 42.81997078301961],
    branches: [
      {
        id: '1',
        nameAr: 'الرونة، أحد رفیدة',
        nameEn: 'Al Laith Road, Al Runah, Ahad Rafidah',
        address: 'Al Laith Road, Al Runah, Ahad Rafidah 67397, Saudi Arabia',
        hotNum: '67397',
        phone: '554324763',
        latLang: [18.222084181289947, 42.82597919922379],
        location: 'https://maps.app.goo.gl/wqV4NezEypkujbzG9',
      },
      {
        id: '2',
        nameAr: 'العزیزیة، أحد رفیدة',
        nameEn: 'Al Aziziyyah, Ahad Rafidah',
        address: 'Al Aziziyyah, Ahad Rafidah 62238, Saudi Arabia',
        hotNum: '62238',
        phone: '554324763',
        latLang: [18.16699562774276, 42.810614968538545],
        location: 'https://maps.app.goo.gl/Mj8W7fSc49CyPUNw9',
      },
    ],
  },
];
